import React, { useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { loadBatches } from "context/ApiService";
import { styled } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import Tooltip from "@mui/material/Tooltip";
import SoftAvatar from "components/SoftAvatar";
import SoftProgress from "components/SoftProgress";
import DownloadReport from "./DownloadReport";
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { useUser } from "context/providers/UserProvider";
import { restartBatchProcess } from "context/ApiService";

function BatchesList(props) {

    const { reloadState } = props;
    const [reload, setReload] = reloadState;
    const { config, profile, user } = useUser();

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });

    useEffect(() => {
        if(config) LoadData();
        if(reload) {
            setReload(false);
        }
    }, [config, profile, user, paginationModel, reload])

    useEffect(() => {
        let interval = setInterval(() => {
            setReload(true);
        }, 10000);
        return () => {
            clearInterval(interval);
        }
    }, [])

    const ClientLogo = styled('img')({
        width: "25px",
        height: "auto",
        marginLeft: "5px"
    });
    const ClientNameTypography = styled(SoftTypography)({
        marginLeft: "10px"
    });

    const [rowCountState, setRowCountState] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);

    const ClientAvatars = (clients) =>
    clients.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <SoftAvatar
          src={image}
          alt="name"
          size="xs"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

    const _restartBatchProcess = async (uid) => {
        try{
            let response = await restartBatchProcess(uid);

            console.log(response, "responseresponseresponseresponse");
        }catch(error){

        }
    }
    const RenderClientLogo = (props) => {
        const { value, row } = props;
        let { logo } = row
        return (
            <SoftBox display="flex" alignItems="center">
                {/* <ClientLogo src={logo?.url} /> */}
                <ClientNameTypography variant="button" fontWeight="medium">
                {value}
                </ClientNameTypography>
            </SoftBox>
        );
    }
    const RenderRecords = (props) => {
        const { value } = props;
        return (
            <SoftBox display="flex" alignItems="center">
                <SoftTypography variant="button" color="text" fontWeight="medium">
                    {value}
                </SoftTypography>
            </SoftBox>
        );
    }
    const RenderCreatedAt = (props) => {
        let { value } = props;
        value = new Date(value).toLocaleString('en-IN',{dateStyle:'medium',timeStyle: "short"});
        
        return (
            <SoftBox display="flex" alignItems="center">
                <SoftTypography variant="button" color="text" fontWeight="medium">
                    {value}
                </SoftTypography>
            </SoftBox>
        );
    }
    const RenderUpdatedAt = (props) => {
        let { value } = props;
        value = new Date(value).toLocaleString('en-IN',{dateStyle:'medium',timeStyle: "short"});
        
        
        return (
            <SoftBox display="flex" alignItems="center">
                <SoftTypography variant="button" color="text" fontWeight="medium">
                    {value}
                </SoftTypography>
            </SoftBox>
        );
    }
    const RenderProgress = (props) => {
        const { value, row } = props;
        let { tooltipLabel, name } = row;
        let statusColor = "info";
        if(value < 25) statusColor = "error";
        else if(value > 25 && value < 50) statusColor = "info";
        else if(value > 50 && value < 75) statusColor = "dark";
        else if(value > 75) statusColor = "success";
        else statusColor = "info";

        return (
            <SoftBox width="8rem" textAlign="left">
                <Tooltip key={name} title={tooltipLabel} placeholder="bottom">
                    <SoftProgress value={value} color={statusColor} variant="gradient" label={false} />
                </Tooltip>
            </SoftBox>
        );
    }

    const RenderClients = (props) => {
        const { value } = props;
        let clients = value.map(ele => {
            let { name, logo } = ele;
            let { url } = logo;
            return [url, name];
        })
        return (
            <SoftBox display="flex" py={1}>
                {ClientAvatars(clients)}
            </SoftBox>
        );
    }
    
    const RenderBatchActions = (props) => {
        const { value, row } = props;
        const { batchStatus } = value;
        const { batch } = row;
        const { uid, lastProfileReport } = batch;
        
        let isShowRefreshButton = false;

        if(lastProfileReport){

            const { updatedAt } = lastProfileReport;

            var today = new Date();
            var lastUpdated = new Date(updatedAt);
            
            const timestamp1 = today.getTime();
            const timestamp2 = lastUpdated.getTime();

            // Calculate the difference in milliseconds
            const differenceInMilliseconds = Math.abs(timestamp1 - timestamp2);

            // Convert milliseconds to minutes
            const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));

            // Show refresh button after 15 minues
            const waitMinutes = 15;

            if(differenceInMinutes > waitMinutes) isShowRefreshButton = true;

            console.log(isShowRefreshButton, "isShowRefreshButtonisShowRefreshButtonisShowRefreshButton");
        }
        
        return (
            <SoftBox display="flex" alignItems="center">
                {(batchStatus == "DONE") && <DownloadReport reloadState={reloadState} batch={value} />}
                {batchStatus == "REVIEW" && <SoftButton type="button" variant="text" color="primary">
                    <RefreshTwoToneIcon />&nbsp;Processing
                </SoftButton>}
                {(batchStatus == "INPROGRESS" || batchStatus == "TOBESTARTED") && isShowRefreshButton && <SoftButton onClick={() => {
                    _restartBatchProcess(uid);
                }} type="button" variant="text" color="primary">
                    <SyncProblemIcon />&nbsp;Refresh
                </SoftButton>}
            </SoftBox>
        );
    }

    const columns = [
        { field: "name", headerName: "Batch Name", align: "left", flex: 1, sortable: false, hideable: false, renderCell: RenderClientLogo},
        { field: "clients", headerName: "Clients", align: "left", flex: 1, sortable: false, hideable: false, renderCell: RenderClients },
        { field: "records", headerName: "Records", align: "left", flex: 1, sortable: false, hideable: false, renderCell: RenderRecords},
        { field: "createdAt", headerName: "Created At", align: "left", flex: 1, sortable: false, hideable: false, renderCell: RenderCreatedAt},
        { field: "updatedAt", headerName: "Updated At", align: "left", flex: 1, sortable: false, hideable: false, renderCell: RenderUpdatedAt},
        { field: "progress", headerName: "Progress", align: "left", flex: 1, sortable: false, hideable: false, renderCell: RenderProgress},
        { field: "batch", headerName: "Action", align: "left", flex: 1, sortable: false, hideable: false, renderCell: RenderBatchActions},
    ];

    const LoadData = async () => {
        let { defaultRole } = config;
        let { role } = profile;
        let isClient = role == defaultRole;
        setLoading(true);
        try{
            let searchData = {
                "page": paginationModel.page,
                "limit": paginationModel.pageSize,
                "search": isClient ? { createdBy: user?.id } : {}
            };

            let response = await loadBatches(searchData);

            setLoading(false);

            let { success, data, message } = response.data;

            if(success){
                
                let { data: batches, limit, page, total } = data;
                batches = batches.map(batch => {
                    let { uid, name, clients, count, createdAt, updatedAt} = batch;
                    let { total: records, done } = count;
                    let progress = Math.floor((done / records) * 100);
                    let tooltipLabel = `${done} / ${records}`
                    return { uid, name, clients, records, createdAt, updatedAt, progress, tooltipLabel, batch }
                })
                
                setRowCountState(total);
                setRows(batches);
            }
        }catch(error){
            setLoading(false);
        }
    }

    return (
        <>
        <DataGrid
            columns={columns}
            rows={rows}
            rowCount={rowCountState}
            loading={isLoading}
            pageSizeOptions={[10, 50, 100]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            getRowId={(row) => row.uid}
            disableColumnMenu
            disableRowSelectionOnClick
            sx={{
                boxShadow: 0,
                border: 0,
                "& .MuiInputBase-root": {
                    width: "70px !important"
                },
                "& .MuiTablePagination-select": {
                    textAlignLast: "center !important"
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                 },
                 "& .MuiDataGrid-virtualScroller": {
                    minHeight: "50px !important"
                 }
            }}
        />
        </>
    );
}

export default BatchesList;
