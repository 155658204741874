import React, { useEffect, useState } from "react";

import SoftButton from "components/SoftButton";
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useForm } from 'react-hook-form';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftAlert from "components/SoftAlert";
import UpdateUserForm from "./UpdateUserForm";
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';

const UpdateUser = (props) => {
    const { isEditable } = props;
    const [isOpne, setOpen] = useState(false);

    const handleModalOpen = () => {
        setOpen(true);
    }

    const handleModalClose = (event, reason) => {
        if (reason && reason === "backdropClick")   return; 
        setOpen(false);
    }
    
    const UpdateUserModal = () => {
        return (
            <Dialog onClose={handleModalClose} open={true}>
                <DialogTitle>Update User</DialogTitle>
                <DialogContent>
                    <UpdateUserForm {...props} setOpen={setOpen} />
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <>
            {isEditable && <SoftButton variant="outlined" color="secondary" onClick={handleModalOpen} sx={{marginRight: "5px"}}>
                <EditTwoToneIcon />
            </SoftButton>}
            {isOpne && <UpdateUserModal />}
        </>
    )
}
export default UpdateUser;