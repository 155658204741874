/**
 =========================================================
 * Soft UI Dashboard React - v4.0.1
 =========================================================
 
 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)
 
 Coded by www.creative-tim.com
 
 =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import BuildByDevelopers from "layouts/dashboard/components/BuildByDevelopers";
import WorkWithTheRockets from "layouts/dashboard/components/WorkWithTheRockets";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";
import { getBatchStats } from "context/ApiService";
import { useUser } from "context/providers/UserProvider";

function Dashboard() {
  const { size } = typography;
  const { chart, items } = reportsBarChartData;
  const { routes, clients, config, profile } = useUser();

  const [isLoading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const [batchStats, setBatchStats] = useState({
    batches: 0,
    today: 0,
    todayYesterdatPercentage: 0,
    yesterday: 0,
    status: "warning",
    defaulters: {
      defaulters: 0,
			today: 0,
			yesterday: 0,
      todayYesterdatPercentage: 0,
      status: "warning",
		}
  });

  useEffect(() => {
    if(config){
      let { defaultRole } = config;
      let { role } = profile;
      let isClient = role == defaultRole;
      if(reload){
        setReload(false);
      }
      loadBatchStats(isClient ? "client": "admin")
    }
  }, [config, profile, reload])

  const loadBatchStats = async (userType) => {

    try{
        let response = await getBatchStats(userType);
        
        let { success, data, message } = response.data;
        if(success) {
          let { today, yesterday, defaulters } = data;
          let todayYesterdatPercentage = yesterday == 0 ? 100 : (today / yesterday) * 100;
          let status = "warning";

          if(todayYesterdatPercentage < 0) {
            status = "error";
            todayYesterdatPercentage = `${todayYesterdatPercentage}%`;
          }else if(todayYesterdatPercentage == 0 && yesterday > 0) {
            todayYesterdatPercentage = `-100%`;
            status = "error";
          }else if(todayYesterdatPercentage == 0) {
            todayYesterdatPercentage = `${todayYesterdatPercentage}%`;
            status = "info";
          }else {
            todayYesterdatPercentage = `+${todayYesterdatPercentage}%`;
            status = "success";
          }

          let { today: defToday, yesterday: defYesterday } = defaulters;

          let defTodayYesterdatPercentage = defYesterday == 0 ? 100 : (defToday / defYesterday) * 100;
          let defStatus = "warning";

          if(defTodayYesterdatPercentage < 0) {
            defTodayYesterdatPercentage = defTodayYesterdatPercentage * -1;
            defStatus = "error";
            defTodayYesterdatPercentage = `-${defTodayYesterdatPercentage}%`;
          }else if(defTodayYesterdatPercentage == 0 && defYesterday > 0) {
            defTodayYesterdatPercentage = `-100%`;
            defStatus = "error";
          }else if(defTodayYesterdatPercentage == 0) {
            defTodayYesterdatPercentage = `${defTodayYesterdatPercentage}%`;
            defStatus = "info";
          }else {
            defTodayYesterdatPercentage = `+${defTodayYesterdatPercentage}%`;
            defStatus = "success";
          }

          setBatchStats({ ...data, todayYesterdatPercentage, status, defaulters: {...defaulters, status: defStatus, todayYesterdatPercentage: defTodayYesterdatPercentage} });

          setLoading(false);
        }
    }catch(error){

    }
  }



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3} sx={{height: "80vh"}}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "today's bacthes" }}
                count={isLoading ? "Loading..." : batchStats?.today}
                // percentage={isLoading ? {} : { color: batchStats?.status, text: batchStats?.todayYesterdatPercentage }}
                icon={{ color: "error", component: "text_snippet" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "today's records" }}
                count={isLoading ? "Loading..." : batchStats?.defaulters?.today}
                // percentage={isLoading ? {} : { color: batchStats?.defaulters?.status, text: batchStats?.defaulters?.todayYesterdatPercentage }}
                icon={{ color: "info", component: "find_in_page" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Total batches" }}
                count={isLoading ? "Loading..." : batchStats?.batches}
                icon={{ color: "warning", component: "source" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "total records" }}
                count={isLoading ? "Loading..." : batchStats?.defaulters?.defaulters}
                icon={{
                  color: "dark",
                  component: "travel_explore",
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7}>
              <BuildByDevelopers />
            </Grid>
            <Grid item xs={12} lg={5}>
              <WorkWithTheRockets />
            </Grid>
          </Grid>
        </SoftBox>
        {/* <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5}>
              <ReportsBarChart
                title="active users"
                description={
                  <>
                    (<strong>+23%</strong>) than last week
                  </>
                }
                chart={chart}
                items={items}
              />
            </Grid>
            <Grid item xs={12} lg={7}>
              <GradientLineChart
                title="Sales Overview"
                description={
                  <SoftBox display="flex" alignItems="center">
                    <SoftBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                      <Icon className="font-bold">arrow_upward</Icon>
                    </SoftBox>
                    <SoftTypography variant="button" color="text" fontWeight="medium">
                      4% more{" "}
                      <SoftTypography variant="button" color="text" fontWeight="regular">
                        in 2021
                      </SoftTypography>
                    </SoftTypography>
                  </SoftBox>
                }
                height="20.25rem"
                chart={gradientLineChartData}
              />
            </Grid>
          </Grid>
        </SoftBox> */}
        {/* <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Projects />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OrderOverview />
          </Grid>
        </Grid> */}
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
