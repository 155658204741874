/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import CircularProgress from '@mui/material/CircularProgress';
import Separator from "layouts/authentication/components/Separator";
import { Routes, Route, useParams } from 'react-router-dom';

// Images
import curved6 from "assets/images/curved-images/curved14.jpg";
import { verifyUserHash, setPassword } from "context/ApiService";
import IconButton from '@mui/material/IconButton';

import VisibilityOnIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import SoftAlert from "components/SoftAlert";
import { useForm } from 'react-hook-form';

function VerifyProfile() {
  const [agreement, setAgremment] = useState(true);
  const [showCard, setCardStatus] = useState(false);
  const [verified, setVerified] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [message, setMessage] = useState({show: false, message: "", state: "success"});
  let { hash } = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger
  } = useForm();

  const handleSetAgremment = () => setAgremment(!agreement);

  useEffect(() => {
    verifyHash(hash);
  }, [hash]);

  const triggerSubmit = async (data) => {
      try{
        console.log(data, "datadatadata");
        let response = await setPassword({ ...data, hash });
        let { success, data: respData, message } = response?.data;
        setMessage({show: true, message: message, state: success ? "success" : "error"});
        navigate("/sign-in");
      }catch(error) {
        if(error?.response?.status == 401 || error?.response?.status == 403){
          setMessage({show: true, message: error?.response?.data?.message, state: "error"})
        }
        console.log(error, "EEEEE");
      }
  }

  const verifyHash = async (hash) => {
      try{
        let response = await verifyUserHash(hash);
        let { success, data } = response.data;
        if(success){
          setCardStatus(true);
          setVerified(true);
        }
      } catch(error){
        
      }
  }

  return (
    <BasicLayout
      title="Welcome!"
      description={verified ? "" : "Please wait while we verify the account."}
      image={curved6}
    >
      <Card>
        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            {!verified ? "Please Wait...": "Set Password"}
          </SoftTypography>
        </SoftBox>
        {!verified && <SoftBox mb={2} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </SoftBox>}
        <SoftBox pt={2} pb={3} px={3}>
        {message?.show && <SoftAlert color={message?.state}>
      <SoftTypography color="loght" component="label" variant="caption" fontWeight="bold">{message?.message}</SoftTypography></SoftAlert>}
          {showCard && <SoftBox component="form" role="form" onSubmit={handleSubmit((data) => triggerSubmit(data))}>
            <SoftBox display="flex" alignItems="center" justifyContent="end">
            <SoftBox flex="1">
              <SoftInput
              {...register('password', {
                  required: 'Password is required'
              })}
              type={passwordVisibility ? "text" : "password"} placeholder="Password" error={errors.password} />
            </SoftBox>
            <IconButton onClick={() => { setPasswordVisibility(!passwordVisibility); }}>
                {passwordVisibility ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
            </IconButton>
            </SoftBox>
            <SoftBox mb={2} mt={1} display="flex" alignItems="center" justifyContent="start">
              {errors.password && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid password</SoftTypography>}
            </SoftBox>
            
            <SoftBox display="flex" alignItems="center">
              <Checkbox checked={agreement} onChange={handleSetAgremment} />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                onClick={handleSetAgremment}
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </SoftTypography>
              <SoftTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                textGradient
              >
                Terms and Conditions
              </SoftTypography>
            </SoftBox>
            <SoftBox mt={4} mb={1}>
              <SoftButton type="submit" variant="gradient" color="dark" fullWidth>
                Set password
              </SoftButton>
            </SoftBox>
            <SoftBox mt={3} textAlign="center">
              <SoftTypography variant="button" color="text" fontWeight="regular">
                Already have an account?&nbsp;
                <SoftTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="dark"
                  fontWeight="bold"
                  textGradient
                >
                  Sign in
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </SoftBox>}
        </SoftBox>
      </Card>
    </BasicLayout>
  );
}

export default VerifyProfile;
