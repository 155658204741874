import axiosHttp from "./AxiosInstance"; 

export const login = (data) => {
    return axiosHttp.post("v1/user/login", data);
}

export const me = () => {
    return axiosHttp.get("v1/user");
}

export const getMyPermissions = () => {
    return axiosHttp.get("v1/user/permissions");
}

export const getMyClients = () => {
    return axiosHttp.get("v1/user/clients");
}

export const loadClients = (data) => {
    return axiosHttp.post("v1/client/search", data);
}

export const loadAllClients = () => {
    return axiosHttp.get("v1/client");
}

export const createClient = (data) => {
    return axiosHttp.post("v1/client", data);
}

export const updateClient = (uid, data) => {
    return axiosHttp.put(`v1/client/uid/${uid}`, data);
}

export const getUsers = (data) => {
    return axiosHttp.post(`v1/user/search`, data);
}

export const getAdmins = (data) => {
    return axiosHttp.post(`v1/user/search/admins`, data);
}

export const getTenants = (data) => {
    return axiosHttp.post(`v1/user/search/tenants`, data);
}

export const roles = (permissions = 0) => {
    let getPermissions = permissions ? "?permissions=1" : ""
    return axiosHttp.get(`v1/role${getPermissions}`);
}

export const inviteUser = (data) => {
    return axiosHttp.post(`v1/user/invite`, data);
}

export const resendInvite = (uid) => {
    return axiosHttp.get(`v1/user/invite/resend/${uid}`);
}

export const verifyUserHash = (hash) => {
    return axiosHttp.get(`v1/user/verify/${hash}`);
}

export const setPassword = (data) => {
    return axiosHttp.post(`v1/user/set-password`, data);
}

export const loadWebsites = () => {
    return axiosHttp.get(`v1/website`);
}

export const updateWebsite = (uid, data) => {
    return axiosHttp.put(`v1/website/${uid}`, data);
}

export const updateWebsiteLogo = (uid, data) => {
    return axiosHttp.patch(`v1/website/${uid}`, data);
}

export const createNewWebsite = (data) => {
    return axiosHttp.post(`v1/website`, data);
}

export const loadCredentials = (siteUid) => {
    return axiosHttp.get(`v1/credential/${siteUid}`);
}

export const updateCredential = (uid, data) => {
    return axiosHttp.put(`v1/credential/${uid}`, data);
}

export const createNewCredential = (data) => {
    return axiosHttp.post(`v1/credential`, data);
}

export const defaulterSingleSearch = (data) => {
    return axiosHttp.post(`v1/defaulters/search`, data);
}

export const uploadBatchFile = (data) => {
    return axiosHttp.post(`v1/batch/upload`, data);
}

export const startBatchProcess = (uid, data) => {
    return axiosHttp.post(`v1/defaulters/batch/start/${uid}`, data);
}

export const restartBatchProcess = (uid) => {
    return axiosHttp.post(`v1/defaulters/batch/restart/${uid}`, {});
}

export const loadBatches = (data) => {
    return axiosHttp.post("v1/batch/search", data);
}

export const loadConfig = () => {
    return axiosHttp.get("configuration");
}

export const downloadReport = (uid) => {
    return axiosHttp.get(`v1/batch-report/download/${uid}`, {
        headers: {
            "Content-Type": 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType: 'blob'
    });
}

export const startReportGeneration = (uid) => {
    return axiosHttp.get(`v1/batch-report/start/${uid}`);
}

export const getBatchStats = (userType) => {
    return axiosHttp.get(`v1/batch/statistics/${userType}`);
}