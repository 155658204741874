import DashboardIcon from '@mui/icons-material/DashboardCustomizeTwoTone';
import BeenhereTwoToneIcon from '@mui/icons-material/BeenhereTwoTone';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import PersonSearchTwoToneIcon from '@mui/icons-material/PersonSearchTwoTone';
import FindInPageTwoToneIcon from '@mui/icons-material/FindInPageTwoTone';
import InsertEmoticonTwoToneIcon from '@mui/icons-material/InsertEmoticonTwoTone';

const sidenavItems = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <DashboardIcon size="12px" />,
    id: "DASHBOARD",
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    route: "/users",
    id: "USER",
    icon: <GroupTwoToneIcon size="12px" />,
    noCollapse: true,
  },
  { type: "title", title: "Clients", key: "account-pages", id: "CLIENT,CLIENT_USER" },
  {
    type: "collapse",
    name: "Accounts",
    key: "accounts",
    route: "/accounts",
    id: "CLIENT",
    icon: <BeenhereTwoToneIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Client Users",
    key: "client-users",
    route: "/client-users",
    id: "CLIENT_USER",
    icon: <InsertEmoticonTwoToneIcon size="12px" />,
    noCollapse: true,
  },
  { type: "title", title: "dapro", key: "dapro-pages", id: "DATASOURCE,CREDENTIALS,BATCH" },
  {
    type: "collapse",
    name: "Data Sources",
    key: "datasources",
    route: "/datasources",
    id: "DATASOURCE",
    icon: <LanguageTwoToneIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Credentials",
    key: "credentials",
    route: "/credentials",
    id: "CREDENTIALS",
    icon: <VpnKeyTwoToneIcon size="12px" />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Search",
  //   key: "search",
  //   route: "/search",
  //   icon: <PersonSearchTwoToneIcon size="12px" />,
  //   noCollapse: true,
  // },
  {
    type: "collapse",
    name: "Batch",
    key: "batch",
    route: "/batch",
    id: "BATCH",
    icon: <FindInPageTwoToneIcon size="12px" />,
    noCollapse: true,
  },
];

export default sidenavItems;
