import React, { useState } from "react";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Card, Divider, Dialog, DialogTitle, DialogContent } from "@mui/material";
import AddToQueueTwoToneIcon from '@mui/icons-material/AddToQueueTwoTone';
import { styled } from '@mui/system';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DatasourceActionForm from "./DatasourceActionForm";

function CreateDatasource(props) {
    const { setReload } = props;
    const [isOpen, setOpen] = useState(false);

    const handleModalOpen = () => {
        setOpen(true);
    }

    const handleModalClose = (event, reason) => {
        if (reason && reason === "backdropClick")   return; 
        setOpen(false);
    }

    const AddWebsiteIcon = styled(AddToQueueTwoToneIcon)({
        height: "40px",
        width: "40px",
        color: "#17c1e8"
    });

    const SizedSoftBox = styled(SoftBox)({
        height: "180px",
        flexDirection: 'column'
    });

    const GreyCard = styled(Card)({
        background: "rgba(255, 255, 255, 0.5)",
        border: "2px dashed #17c1e8",
        cursor: "pointer"
    });

    const AddWebsiteModal = () => {
        return (
            <Dialog onClose={handleModalClose} open={true}>
                <DialogTitle>Add New Website</DialogTitle>
                <DialogContent>
                    <DatasourceActionForm {...props} setOpen={setOpen} />
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <>
            <GreyCard onClick={handleModalOpen}>
                <SizedSoftBox mx={3} display="flex" justifyContent="center" alignItems="center">
                    <AddWebsiteIcon />
                    <SoftTypography component="h6" variant="h6" fontWeight="bold">
                        Add New Website
                    </SoftTypography>
                </SizedSoftBox>
            </GreyCard>
            {isOpen && <AddWebsiteModal />}
        </>
    )
}

export default CreateDatasource;