import React, { useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { getAdmins, resendInvite, getTenants } from "context/ApiService";
import { styled } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import UpdateUser from "./update-user";

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import RefreshIcon from '@mui/icons-material/RefreshOutlined';

import Tooltip from "@mui/material/Tooltip";
import SoftAvatar from "components/SoftAvatar";
import SoftProgress from "components/SoftProgress";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function UsersList(props) {

    const { reloadState, config, isTenants } = props;
    const [reload, setReload] = reloadState;

    const SwalAlert = withReactContent(Swal)

    const ClientLogo = styled('img')({
        width: "25px",
        height: "auto",
        marginLeft: "5px"
    });
    const ClientNameTypography = styled(SoftTypography)({
        marginLeft: "30px"
    });

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });

    const [search, setSearch] = useState(null);

    const [rowCountState, setRowCountState] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    const resendUserInvitation = async (user) => {
        let { uid } = user;

        try{
            let { data: userInvite } = await resendInvite(uid);
            let { success, message } = userInvite;
            console.log(userInvite, "userInviteuserInvite");
            if(success){
                SwalAlert.fire({
                    icon: "success",
                    title: "Success!",
                    text: message,
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    setReload(true);
                })
            }
            
        }catch(error){

        }
    }

    const ClientAvatars = (clients) =>
    clients.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <SoftAvatar
          src={image}
          alt="name"
          size="xs"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

    const RenderClients = (props) => {
        const { value } = props;
        
        let clients = value.map(ele => {
            if(ele && Object.keys(ele).length){
                let { name, logo } = ele;
                let { url } = logo;
                return [url, name];
            } else {
                return ["", ""]
            }
        });

        return (
            <SoftBox display="flex" py={1}>
                {ClientAvatars(clients)}
            </SoftBox>
        );
    }

    const RenderEmail = (props) => {
        const { value, row } = props;
        const { emailVerified } = row;
        let email = value.replace(/^(.)(.*)(.@.*)$/, (_, a, b, c) => a + b.replace(/./g, '*') + c);
        return (
            <SoftBox display="flex" alignItems="center" justifyContent="space-between">
                <SoftTypography variant="button" color="text" fontWeight="medium">
                    {email}
                </SoftTypography>
                &nbsp;
                <SoftTypography variant="text" color={emailVerified ? "success" : "error"} fontWeight="medium" sx={{ marginTop: "5px"}}>
                    {emailVerified ? <CheckCircleOutlineOutlinedIcon /> : <CancelOutlinedIcon /> }
                </SoftTypography>
            </SoftBox>
        );
    }

    const RenderName = (props) => {
        const { value: firstName, row } = props;
        const { lastName, profileComplete } = row;

        return (
            <SoftBox display="flex" alignItems="center" justifyContent="space-between">
                <SoftTypography variant="button" color="text" fontWeight="medium">
                    {firstName ? firstName : ""} {lastName ? lastName : ""}
                </SoftTypography>
                &nbsp;
                <SoftTypography variant="text" color={profileComplete ? "success" : "error"} fontWeight="medium" sx={{ marginTop: "5px"}}>
                    {profileComplete ? <CheckCircleOutlineOutlinedIcon /> : <CancelOutlinedIcon /> }
                </SoftTypography>
            </SoftBox>
        );
    }

    const RenderPhone = (props) => {
        const { value, row } = props;
        const { countryCode, dialNumber } = value;
        let hasData = countryCode && dialNumber && (countryCode != "false" || dialNumber != "false") && (countryCode != "true" || dialNumber != "true")
        let phoneNumberMasked = hasData ? dialNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 - $3') : "0000000000";
        return (
            hasData ?
            <SoftBox display="flex" alignItems="center" justifyContent="space-between">
                <SoftTypography variant="button" color="text" fontWeight="medium">
                    {countryCode} {phoneNumberMasked}
                </SoftTypography>
            </SoftBox>
            :
            <SoftBox display="flex" alignItems="center" justifyContent="space-between">
                <SoftTypography variant="button" color="text" fontWeight="medium">
                    NA
                </SoftTypography>
            </SoftBox>
        );
    }

    const RenderDate = (props) => {
        const { value } = props;
        let date = new Date(value).toLocaleString("en-IN", { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true})
        return (
            <SoftBox display="flex" alignItems="center">
                <SoftTypography variant="button" color="text" fontWeight="medium">
                    {date}
                </SoftTypography>
            </SoftBox>
        );
    }

    const RenderText = (props) => {
        const { value } = props;
        return (
            value ? <SoftBox display="flex" alignItems="center">
                <SoftTypography variant="button" color="text" fontWeight="medium">
                    {value}
                </SoftTypography>
            </SoftBox> : <SoftBox display="flex" alignItems="center">
                <SoftTypography variant="button" color="text" fontWeight="medium">
                    NA
                </SoftTypography>
            </SoftBox>
        );
    }
    
    const RenderClientActions = (props) => {
        const { value, row } = props;
        let { isEditable, emailVerified, action } = row;
        return (
            <>
                <SoftBox display="flex" alignItems="center">
                    <UpdateUser reloadState={reloadState} isEditable={isEditable} client={row} />
                    {!emailVerified && <Tooltip key={`resend-invite-${value.uid}`} title={`Resend Invitation`} placeholder="top">
                        <SoftButton type="button" color="primary" onClick={() => {
                            resendUserInvitation(action);
                        }}>
                            <RefreshIcon />
                        </SoftButton>
                    </Tooltip>}
                    
                </SoftBox>
            </>
        );
    }

    useEffect(() => {
        LoadData();
        return () => {
            setRows([]);
        }
    }, []);
    
    useEffect(() => {
        const mainColumns = [
            { field: "firstName", headerName: "Full Name", align: "left", flex: 1, sortable: false, hideable: false, renderCell: RenderName },
            { field: "emailAddress", headerName: "Email Address", align: "left", flex: 1, sortable: false, hideable: false, renderCell: RenderEmail},
            { field: "phoneNumber", headerName: "Phone Number", align: "left", flex: 1, sortable: false, hideable: false, renderCell: RenderPhone},
            { field: "gender", headerName: "Gender", align: "left", sortable: false, hideable: false, renderCell: RenderText},
            { field: "role", headerName: "Role", align: "left", sortable: false, hideable: false, renderCell: RenderText},
        ];
        let tenantColumn = [
            { field: "clients", headerName: "Clients", align: "left", sortable: false, hideable: false, renderCell: RenderClients },
        ];
        let actionColumns = [
            { field: "action", headerName: "Action", align: "left", flex: 1, sortable: false, hideable: false, renderCell: RenderClientActions},
        ];

        let allColumns = [
            ...mainColumns,
            ...isTenants ? tenantColumn : [],
            ...actionColumns
        ];

        setColumns(allColumns);
    }, [isTenants]);

    useEffect(() => {
        LoadData();
    }, [paginationModel, isTenants]);

    useEffect(() => {
        if(reload) {
            LoadData();
            setReload(false);
        }
    }, [reload]);

    useEffect(() => {
        if(config){
            // const { defaultRole } = config;
            // setSearch({
            //     "profile": {
            //         "profile.role": { "$ne": defaultRole }
            //     }
            // });

            LoadData();
            setReload(false);
        }
    }, [config])

    const LoadData = async () => {
        setLoading(true);
        try{
            let searchData = {
                "page": paginationModel.page,
                "limit": paginationModel.pageSize,
                ...search && Object.keys(search).length ? { search } : {}
            };

            let response = isTenants ? await getTenants(searchData) : await getAdmins(searchData);

            setLoading(false);

            let { success, data, message } = response.data;

            if(success){
                let { data: users, limit, page, total } = data;
                console.log(users, "usersusers");
                let usersList = users.map(user => {
                    let { personal, profile, emailAddress, uid } = user;
                    let { firstName, lastName, gender } = personal;
                    let { emailVerified, phoneNumber, profileComplete, role: { name: roleName }, createdAt, isEditable, clients } = profile;

                    return {
                        uid,
                        emailAddress,
                        firstName, lastName, gender,
                        emailVerified, phoneNumber, profileComplete,
                        role: roleName,
                        createdAt,
                        isEditable,
                        action: user,
                        clients
                    }
                })
                setRowCountState(total);
                setRows(usersList);
            }
        }catch(error){
            setLoading(false);
        }
    }

    return (
        <>
        <DataGrid
            columns={columns}
            rows={rows}
            rowCount={rowCountState}
            loading={isLoading}
            pageSizeOptions={[10, 50, 100]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            getRowId={(row) => row.uid}
            disableColumnMenu
            disableRowSelectionOnClick
            localeText={{ noResultsOverlayLabel: "Section looks boring. Wanna add few users?" }}
            sx={{
                boxShadow: 0,
                border: 0,
                "& .MuiInputBase-root": {
                    width: "70px !important"
                },
                "& .MuiTablePagination-select": {
                    textAlignLast: "center !important"
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                 },
                 "& .MuiDataGrid-virtualScroller": {
                    minHeight: "50px !important"
                 }
            }}
        />
        </>
    );
}

export default UsersList;
