/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";

// Images
import curved6 from "assets/images/curved-images/curved14.jpg";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

function TermsAndConditions(props) {
  const { handleClose } = props;
  const [agreement, setAgremment] = useState(true);

  const handleSetAgremment = () => setAgremment(!agreement);

  const style = {
    position: 'absolute',
    top: '50px',
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: "calc(100% - 100px)",
    height: "calc(100% - 100px)",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "15px",
    p: 4,
    overflowY: "scroll",
  };

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h3" component="h3">
          Terms and Conditions
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }} variant="body2" component="p">
          Please carefully read the terms of use before accessing services on this platform. Your use of services through this platform indicates your acceptance and legal agreement to be bound by these terms. If you disagree, please refrain from using this platform and its services.
        </Typography>
        <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ mt: 2 }}>
          Introduction
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }} variant="body2" component="p">
          This document constitutes an agreement ("Agreement") between You and Creditap ("Creditap Fintech" "We," or "Us"), the operator of dapro.creditap.ai (the "portal") and DAPRO services provided by Creditap Fintech via the portal. By using or accessing the Services, and/or by registering with Creditap Fintech, you agree to the terms of this Agreement. The portal is operated and owned by M/s CREDITAP FINTECH PRIVATE LIMITED. Your continued use of the portal and/or DAPRO services following any modifications constitutes your acknowledgment and agreement to abide by the modified terms.
        </Typography>
        <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ mt: 2 }}>
          Eligibility
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }} variant="body2" component="p">
          You must be 18 years or older to register with DAPRO portal or use its services. By using DAPRO, you represent and warrant that You have the right, authority, and capacity to enter these Terms of Use. Violation of these terms may result in profile deletion and access prohibition.
        </Typography>
        <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ mt: 2 }}>
          Compliance with Law
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }} variant="body2" component="p">
          This Agreement complies with all relevant laws, including the Indian Contract Act, 1872; Information Technology Act, 2000; and the SPI Rules and IG Rules. Creditap Fintech reserves the right to modify or terminate services, with any changes reflected in the terms and conditions.
        </Typography>
        <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ mt: 2 }}>
          Privacy Policy
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }} variant="body2" component="p">
         To access the DAPRO portal or dashboard you must have the agreement signed, subject to Creditap's Privacy Policy. Creditap may be used (only on a need basis), share, and maintain information in accordance with relevant laws. Use of the DAPRO service by user implies consent (taken by user) for processing private data through specified channels.
        </Typography>
        <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ mt: 2 }}>
          Content and Data Protection Legislation
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }} variant="body2" component="p">
        Creditap processes information provided by users, and moderation is at its discretion. Users grant Creditap a license to use posted information. Data protection legislation complies with applicable laws.
        </Typography>
        <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ mt: 2 }}>
          Responsibilities of the User
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }} variant="body2" component="p">
          Users agree not to upload or share prohibited content. They shall not misuse the platform or provide incomplete or false information. Unsolicited communications and misuse of site contents are prohibited.
        </Typography>
        <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ mt: 2 }}>
          Representation of Creditap and User
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }} variant="body2" component="p">
          Creditap reserves the right to advertise on its platforms but does not endorse advertised services. Users agree to lawfully use and accept responsibility for their actions on the platform.
        </Typography>
        <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ mt: 2 }}>
        Disclaimer and Suggestions
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }} variant="body2" component="p">
        Creditap makes no guarantees or warranties regarding professional qualifications or content accuracy. Users should perform due diligence before engaging with lenders or borrowers. Creditap is not liable for any decisions made based on platform content.
        </Typography>
        <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ mt: 2 }}>
        Disclaimer of Warranties
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }} variant="body2" component="p">
        Creditap disclaims liability for user reliance on services and platform content. It has no control over user-generated content and is not liable for user actions.
        </Typography>
        <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ mt: 2 }}>
        Indemnity
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }} variant="body2" component="p">
        Users agree to indemnify Creditap from third-party claims arising from their use of services. In no event shall Creditap's total liability exceed Rs. 1000.
        </Typography>
        <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ mt: 2 }}>
        Termination
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }} variant="body2" component="p">
        Creditap may suspend or terminate user access for various reasons, including breaches of terms or illegal activities. Creditap reserves the right to disable or allow re-registration at its discretion.
        </Typography>
        <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ mt: 2 }}>
        Assignment
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }} variant="body2" component="p">
        Creditap may assign this contract at any time, while users may not assign or transfer it.
        </Typography>
        <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ mt: 2 }}>
        Waiver
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }} variant="body2" component="p">
        No waiver of terms by Creditap shall affect its rights unless in writing.
        </Typography>
        <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ mt: 2 }}>
        Severability
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }} variant="body2" component="p">
        If any provision is deemed invalid, the remaining terms remain in force.
        </Typography>
        <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ mt: 2 }}>
        Entire Agreement
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }} variant="body2" component="p">
        This Agreement supersedes previous agreements and understandings.
        </Typography>
        <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ mt: 2 }}>
        Supplementary Agreement
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }} variant="body2" component="p">
        This Agreement is to be read with the Privacy Policy, with conflicting provisions resolved in favor of this Agreement.
        </Typography>
        <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ mt: 2 }}>
        Arbitration
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }} variant="body2" component="p">
        Disputes shall be referred to arbitration in Bengaluru, governed by the Arbitration and Conciliation Act, 1996.
        </Typography>
        <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ mt: 2 }}>
        Applicable Law and Jurisdiction
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }} variant="body2" component="p">
        This Agreement is governed by Indian laws, and Bengaluru courts have exclusive jurisdiction.
        </Typography>
        <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ mt: 2 }}>
        Audit Rights
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }} variant="body2" component="p">
        On written request from Customer, Creditap shall provide written responses (on a confidential basis) to all reasonable requests for information made by Customer related to its processing of Personal Data, including responses to information security and audit questionnaires that are strictly necessary to confirm Creditap’s compliance with data privacy governing law, provided that Customer shall not exercise this right more than once in any rolling 12 month period. Notwithstanding the foregoing, Customer may also exercise such audit right in the event Customer is expressly requested or required to provide this information to a data protection authority, or Creditap has experienced a Breach or other reasonably similar basis.
        </Typography>
        <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ mt: 2 }}>
        Cancellation and Refund Policy
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }} variant="body2" component="p">
        Cancellation and refunds are not applicable.
        </Typography>
        <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ mt: 2 }}>
        Grievance Officer
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }} variant="body2" component="p">
        Grievances are to be addressed to Creditap in writing or through email - <Typography id="modal-modal-description" sx={{ mt: 1 }} variant="body2" component="a" href="mailto:hello@creditap.ai">hello@creditap.ai</Typography> Creditap will address grievances in accordance with applicable laws.
        </Typography>

        <SoftButton type="button" variant="gradient" color="error" sx={{ mt: 3, mb: 1 }} onClick={handleClose}>
          Accept & Continue!
        </SoftButton>
      </Box>
    </Modal>
  );
}

export default TermsAndConditions;
