import React, { useEffect, useState } from "react";

import SoftButton from "components/SoftButton";
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useForm } from 'react-hook-form';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftAlert from "components/SoftAlert";
import UpdateBrandForm from "./UpdateBrandForm";
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';

const UpdateBrand = (props) => {
    const [isOpne, setOpen] = useState(false);

    const handleModalOpen = () => {
        setOpen(true);
    }

    const handleModalClose = (event, reason) => {
        if (reason && reason === "backdropClick")   return; 
        setOpen(false);
    }
    
    const UpdateBrandModal = () => {
        return (
            <Dialog onClose={handleModalClose} open={true}>
                <DialogTitle>Update Client</DialogTitle>
                <DialogContent>
                    <UpdateBrandForm {...props} setOpen={setOpen} />
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <>
            <SoftButton variant="outlined" color="secondary" onClick={handleModalOpen}>
                <EditTwoToneIcon />
            </SoftButton>
            {isOpne && <UpdateBrandModal />}
        </>
    )
}
export default UpdateBrand;