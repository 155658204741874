import React, { useEffect, useState } from "react";

import SoftButton from "components/SoftButton";
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useForm } from 'react-hook-form';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftAlert from "components/SoftAlert";
import { createNewWebsite, updateWebsite } from "context/ApiService";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const DatasourceActionForm = (props) => {
    const { setReload, setOpen, priority, website } = props;
    const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm();

    const [levels, setLevels] = useState([]);
    const [levelVal, setLevelVal] = useState('');

    useEffect(() => {
        if(website){
            console.log(website, "websitewebsitewebsitewebsite");
            let {name, priority, url, queueName, level} = website;
            setValue("name", name);
            setValue("url", url);
            setValue("queueName", queueName);
            setValue("level", level ? level.toString(): "");
            setLevelVal(level ? level.toString(): "");
        }
    }, [website]);

    useEffect(() => {
        let allLevels = [
            {label: "First", value: "1"},
            {label: "Second", value: "2"},
            {label: "Third", value: "3"}
        ];

        setLevels(allLevels);
    }, []);

    const triggerSubmit = (formData) => {
        let currentPriority = priority + 1;
        let fd = { ...formData, priority: currentPriority };
        if(website){
            updateWebsiteData(fd);
        }else{
            saveWebsite(fd);
        }
    }

    const saveWebsite = async (formData) => {
        try{
            let response = await createNewWebsite(formData);
            let { success, data, message } = response.data;
            if(success){
                setOpen(false);
                setReload(true);
            }
        }catch(error){

        }
    }
    
    const updateWebsiteData = async (formData) => {
        try{
            formData = { ...formData, priority: website?.priority }
            let response = await updateWebsite(website?.uid, formData);
            let { success, data, message } = response.data;
            if(success){
                setOpen(false);
                setReload(true);
            }
        }catch(error){

        }
    }
    
    return (
        <SoftBox component="form" onSubmit={handleSubmit((data, event) => triggerSubmit(data, event))} role="form" sx={{minWidth: "300px"}}>
            <SoftBox mb={1}>
                <SoftBox mb={0.5} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Website Name
                    </SoftTypography>
                </SoftBox>
                <SoftInput
                    type="text"
                    {...register('name', {
                        required: true
                    })}
                    placeholder="Name"
                    error={errors.name}
                />
                {errors.name && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid name.</SoftTypography>}
            </SoftBox>

            <SoftBox mb={1}>
                <SoftBox mb={0.5} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Website Base URL
                    </SoftTypography>
                </SoftBox>
                <SoftInput
                    type="text"
                    {...register('url', {
                        required: true
                    })}
                    placeholder="Base URL"
                    error={errors.url}
                />
                {errors.url && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid URL</SoftTypography>}
            </SoftBox>

            <SoftBox mb={1}>
                <SoftBox mb={0.5} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Queue Name
                    </SoftTypography>
                </SoftBox>
                <SoftInput
                    type="text"
                    {...register('queueName', {
                        required: true
                    })}
                    placeholder="Queue Name"
                    error={errors.queueName}
                />
                {errors.queueName && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid queue name.</SoftTypography>}
            </SoftBox>

            <SoftBox mb={1}>
                <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Level
                    </SoftTypography>
                </SoftBox>
                <Autocomplete
                    disablePortal
                    disableClearable
                    id="combo-box-demo"
                    value={levelVal}
                    inputValue={levelVal}
                    options={levels}
                    // sx={{ width: 300 }}
                    {...register('level', {
                        required: 'Level is required'
                    })}
                    onChange={(event, newValue) => {
                        let { value } = newValue;
                        setValue("level", value);
                        setLevelVal(value);
                    }}
                    renderInput={(params) => <TextField {...params}/>}
                />
                {errors.level && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid Level</SoftTypography>}
            </SoftBox>
            
            <SoftBox mt={4} mb={1}>
            <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                Save
            </SoftButton>
            <SoftButton type="button" variant="text" color="error" fullWidth sx={{ marginTop: "15px"}} onClick={() => { setOpen(false) }}>
                Cancel
            </SoftButton>
            </SoftBox>
        </SoftBox>
    )
}
export default DatasourceActionForm;