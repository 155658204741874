import React, { useEffect, useState } from "react";

import SoftButton from "components/SoftButton";
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useForm } from 'react-hook-form';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftAlert from "components/SoftAlert";
import { updateClient } from "context/ApiService";
import { styled } from '@mui/system';

const UpdateUserForm = (props) => {
    const { reloadState, setOpen, client } = props;
    const [reload, setReload] = reloadState;
    const { register, handleSubmit, formState: { errors }, trigger, setValue } = useForm();
    const [clientLogo, setClientLogo] = useState("");

    const ClientLogo = styled('img')({
        width: "25px",
        height: "auto",
        marginLeft: "10px"
    });

    const triggerSubmit = (formData, event) => {
        console.log(formData, "eventeventevent");
        let { name, code, file: files } = formData;

        var fd = new FormData();
        fd.append("name", name);
        fd.append("code", code);

        if(files.length){
            let file = files[0];
            fd.append("file", file)
        }
        
        saveClient(fd);
    }

    const saveClient = async (formData) => {
        try{
            let response = await updateClient(client.uid, formData);
            let { success, data, message } = response.data;
            if(success){
                setOpen(false);
                setReload(true);
            }
        }catch(error){

        }
    }

    useEffect(() => {
        let { name, code } = client;
        setValue("name", name);
        setValue("code", code);
        setClientLogo(client?.logo?.url);
    }, [client]);
    
    return (
        <SoftBox component="form" onSubmit={handleSubmit((data, event) => triggerSubmit(data, event))} role="form">
            <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                Client Name
                </SoftTypography>
            </SoftBox>
            <SoftInput
                type="text"
                {...register('name', {
                    required: true,
                    minLength: 5
                })}
                placeholder="Name"
                error={errors.name}
            />
            {errors.name && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid name. Min 5 Letters</SoftTypography>}
            </SoftBox>

            <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                Client Code
                </SoftTypography>
            </SoftBox>
            <SoftInput
                type="text"
                {...register('code', {
                    required: true,
                    maxLength: 4
                })}
                placeholder="Code"
                error={errors.code}
            />
            {errors.code && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid Code. All Uppercase</SoftTypography>}
            </SoftBox>

            <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                Logo
                </SoftTypography>
            </SoftBox>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={1} ml={0.5}>
                <SoftInput
                    type="file"
                    inputProps={{ accept: 'image/png, image/jpg, image/jpeg' }}
                    {...register('file', { required: false })}
                    placeholder="file"
                    error={errors.file}
                    onChange={(event) => {
                        setClientLogo(URL.createObjectURL(event.target.files[0]));
                    }}
                />
                <ClientLogo src={clientLogo} />
            </SoftBox>
            
            {errors.file && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please Select a file</SoftTypography>}
            </SoftBox>
            <SoftBox mt={4} mb={1}>
            <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                Save
            </SoftButton>
            <SoftButton type="button" variant="text" color="error" fullWidth sx={{ marginTop: "15px"}} onClick={() => { setOpen(false) }}>
                Cancel
            </SoftButton>
            </SoftBox>
        </SoftBox>
    )
}
export default UpdateUserForm;