import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import Drawer from '@mui/material/Drawer';
import CreateDatasource from "./CreateDatasource";
import DatasourcePreview from "./DatasourcePreview";
import { loadWebsites } from "context/ApiService";
import CredentialsList from "../credentials/CredentialsList";
import { styled } from '@mui/system';

// MuiDrawer-paper
function ListOfDatasources(props) {
    const [reload, setReload] = useState(true);
    const [websites, setWebsites] = useState([]);
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [selectedWebsite, setWebsite] = useState(false);
    const { isCredentials } = props;

    // const ResizedDrawer = styled(Drawer)({
    //     "& .MuiDrawer-paper": {
    //         width: "400px"
    //     }
    // });

    useEffect(() => {
        if(reload) {
            setReload(false);
            loadAllWebsites();
        }
    }, [reload])

    const loadAllWebsites = async () => {
        try{
            let response = await loadWebsites();

            let { success, data, message } = response.data;

            if(success){
                setWebsites(data);
            }
        }catch(error){

        }
    }

    return (
        <>
            <Grid container spacing={3}>
                {!isCredentials && <Grid xs={4}>
                    <CreateDatasource setReload={setReload} priority={websites?.length} />
                </Grid>}
                {websites.map(website => {
                    return <Grid key={website?.uid} xs={4}>
                        <DatasourcePreview {...props} setWebsite={setWebsite} setDrawerOpen={setDrawerOpen} website={website} setReload={setReload} />
                    </Grid>
                })}
            </Grid>
            <Drawer
                anchor={'right'}
                open={isDrawerOpen}
                onClose={() => {
                    setDrawerOpen(false);
                }}>
                <CredentialsList website={selectedWebsite} />
            </Drawer>
        </>
    )
}

export default ListOfDatasources;