import React, { useEffect, useState } from "react";

import SoftButton from "components/SoftButton";
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useForm } from 'react-hook-form';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftAlert from "components/SoftAlert";
import { updateCredential, createNewCredential } from "context/ApiService";
import TextArea from "components/TextArea";

const CredentialActionForm = (props) => {
    const { setReload, setOpen, credential, website } = props;
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();

    useEffect(() => {
        if(credential){
            let {username, emailAddress, phoneNumber, password, token} = credential;
            setValue("username", username);
            setValue("phoneNumber", phoneNumber);
            setValue("emailAddress", emailAddress);
            setValue("password", password);
            setValue("token", token);
        } else {
            reset();
        }
    }, [credential, website]);

    const triggerSubmit = (formData) => {
        let fd = { ...formData, website: website?.uid };
        console.log(fd, "fdfdfdfdfdfd");
        if(credential){
            updateCredentialData(fd);
        }else{
            saveCredential(fd);
        }
    }

    const saveCredential = async (formData) => {
        try{
            let response = await createNewCredential(formData);
            let { success, data, message } = response.data;
            if(success){
                setOpen(false);
                setReload(true);
            }
        }catch(error){

        }
    }
    
    const updateCredentialData = async (formData) => {
        try{
            let response = await updateCredential(credential?.uid, formData);
            let { success, data, message } = response.data;
            if(success){
                setOpen(false);
                setReload(true);
            }
        }catch(error){

        }
    }
    
    return (
        <SoftBox component="form" onSubmit={handleSubmit((data, event) => triggerSubmit(data, event))} role="form" sx={{minWidth: "300px"}}>
            <SoftBox mb={1}>
                <SoftBox mb={0.5} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Username
                    </SoftTypography>
                </SoftBox>
                <SoftInput
                    type="text"
                    {...register('username')}
                    placeholder="Username"
                    error={errors.username}
                />
                {errors.username && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid Username.</SoftTypography>}
            </SoftBox>

            <SoftBox mb={1}>
                <SoftBox mb={0.5} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Email Address
                    </SoftTypography>
                </SoftBox>
                <SoftInput
                    type="text"
                    {...register('emailAddress')}
                    placeholder="Email Address"
                    error={errors.emailAddress}
                />
                {errors.emailAddress && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid Email Address</SoftTypography>}
            </SoftBox>

            <SoftBox mb={1}>
                <SoftBox mb={0.5} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Phone Number
                    </SoftTypography>
                </SoftBox>
                <SoftInput
                    type="text"
                    {...register('phoneNumber')}
                    placeholder="Phone Number"
                    error={errors.phoneNumber}
                />
                {errors.phoneNumber && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid Phone Number.</SoftTypography>}
            </SoftBox>

            <SoftBox mb={1}>
                <SoftBox mb={0.5} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Password
                    </SoftTypography>
                </SoftBox>
                <SoftInput
                    type="text"
                    {...register('password')}
                    placeholder="Password"
                    error={errors.password}
                />
                {errors.password && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid Password.</SoftTypography>}
            </SoftBox>

            <SoftBox mb={1}>
                <SoftBox mb={0.5} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Token
                    </SoftTypography>
                </SoftBox>
                <SoftInput
                    type="text"
                    {...register('token')}
                    placeholder="Token"
                    error={errors.token}
                />
                {/* <TextArea minRows={3} resize={false} /> */}
                {errors.token && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid Token.</SoftTypography>}
            </SoftBox>
            
            <SoftBox mt={4} mb={1}>
            <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                Save
            </SoftButton>
            <SoftButton type="button" variant="text" color="error" fullWidth sx={{ marginTop: "15px"}} onClick={() => { setOpen(false) }}>
                Cancel
            </SoftButton>
            </SoftBox>
        </SoftBox>
    )
}
export default CredentialActionForm;