import React, { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import ListOfWebsites from "../datasources/ListOfDatasources";
import { styled } from '@mui/system';
import { IconButton, Divider, CardContent } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroidOutlined';
import UserIcon from '@mui/icons-material/PersonOutline';
import LockIcon from '@mui/icons-material/LockOutlined';
import KeyIcon from '@mui/icons-material/KeyOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { loadCredentials } from "context/ApiService";

import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import CredentialActionForm from "./CredentialActionForm";
import { updateCredential } from "context/ApiService";

function CredentialsList(props) {
  let { website } = props;
  const [isVisible, setVisibility] = useState(false);
  const [credentials, setCredentials] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [selectedCredential, setSelectedCredential] = useState(false);

  const ContainerSoftBox = styled(SoftBox)({
      
  });

  useEffect(() => {
    if(reload){
      loadCredentialsByWebsiteUid();
      setReload(false);
    }
  }, [reload]);

  const handleModalOpen = () => {
      setOpen(true);
  }

  const handleModalClose = (event, reason) => {
      if (reason && reason === "backdropClick")   return; 
      setOpen(false);
  }

  const StyledSoftBox = styled(SoftBox)({
      flexDirection: 'column'
  });
  const FlexSoftBox = styled(SoftBox)({
      flex: 1
  });
  const ListItemCard = styled(Card)({
      padding: "10px",
      marginBottom: "5px"
  });
  const EmptyBoxIcon = styled("img")({
      width: "60px",
      height: "auto",
      marginBottom: "10px"
  });

  const WebsiteDomain = ({link}) => {
      let url = new URL(link);
      return <SoftTypography component="a" href={link} target="_blank" variant="caption" color="secondary">
          {url?.hostname || url?.host}
      </SoftTypography>
  }

  useEffect(() => {
    loadCredentialsByWebsiteUid();
  }, [website]);

  const loadCredentialsByWebsiteUid = async () => {
    
    try{
        let response = await loadCredentials(website?.uid);

        let { success, data, message } = response.data;
        if(success){
          setCredentials(data);
        }
    }catch(error){

    }
  }

  const onClickUpdateBtn = (cred) => {
    handleModalOpen();
    setSelectedCredential(cred);
  }

  const AddCredentialsModal = () => {
        return (
            <Dialog onClose={handleModalClose} open={true}>
                <DialogTitle>{selectedCredential ? 'Update' : 'Add New'} Credential</DialogTitle>
                <DialogContent>
                    <CredentialActionForm {...props} setOpen={setOpen} website={website} setReload={setReload} credential={selectedCredential} />
                </DialogContent>
            </Dialog>
        )
    }

  const updateCredentialData = async (credential) => {
      try{
          let response = await updateCredential(credential?.uid, { status: false });
          let { success, data, message } = response.data;
          if(success){
              setReload(true);
          }
      }catch(error){

      }
  }

  const maskString = (string) => {
    string = isVisible ? string : Array.from(string, c => Math.random() < 0.5 ? '*' : c).join('');
    return string;
  }

  const CedentialListItem = ({ credential }) => {
    return (
      <ListItemCard>
        <SoftBox component="li" display="flex" alignItems="center" py={1} mb={1}>
          <SoftBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            {credential?.username &&
              <SoftBox
                display="flex"
                alignItems="center"
                justifyContent="start"
                sx={{marginBottom: "5px"}}>
                <UserIcon />
                <SoftTypography variant="button" fontWeight="medium" sx={{ marginLeft: "5px"}}>
                  {maskString(credential?.username)}
                </SoftTypography>
              </SoftBox>
            }

            {credential?.emailAddress && <SoftBox
              display="flex"
              alignItems="center"
              justifyContent="start"
              sx={{marginBottom: "5px"}}>
              <EmailIcon />
              <SoftTypography variant="caption" color="text" sx={{ marginLeft: "5px"}}>
                {maskString(credential?.emailAddress)}
              </SoftTypography>
            </SoftBox>}

            {credential?.token && <SoftBox
              display="flex"
              alignItems="center"
              justifyContent="start"
              sx={{marginBottom: "5px"}}>
              <KeyIcon />
              <SoftTypography variant="caption" color="text" sx={{ marginLeft: "5px"}}>
                Token {maskString(credential?.token)}
              </SoftTypography>
            </SoftBox>}

            {credential?.phoneNumber && <SoftBox
              display="flex"
              alignItems="center"
              justifyContent="start"
              sx={{marginBottom: "5px"}}>
              <PhoneAndroidIcon />
              <SoftTypography variant="caption" color="text" sx={{ marginLeft: "5px"}}>
                {maskString(credential?.phoneNumber)}
              </SoftTypography>
            </SoftBox>}

            {credential?.password && <SoftBox
              display="flex"
              alignItems="center"
              justifyContent="start">
              <LockIcon />
              <SoftTypography variant="caption" color="text" sx={{ marginLeft: "5px"}}>
                {maskString(credential?.password)}
              </SoftTypography>
            </SoftBox>}
          </SoftBox>
        </SoftBox>
        <SoftBox
          display="flex"
          alignItems="center"
          justifyContent="start">
            <SoftButton component="button" variant="text" size="small" color={"primary"} startIcon={<EditIcon />} onClick={() => {
              onClickUpdateBtn(credential);
            }}>Update</SoftButton>
            <SoftButton component="button" variant="text" size="small" color={"error"} startIcon={<DeleteIcon />} onClick={() => {
              updateCredentialData(credential);
            }}>DELETE</SoftButton>
        </SoftBox>
      </ListItemCard>
    )
  }

  const EmptyListCard = () => {
    return <ListItemCard>
      <SoftBox display="flex" alignItems="center" justifyContent="start" flexDirection="column" my={2}>
          <EmptyBoxIcon src="/assets/images/empty-box.png" />
          <SoftTypography component="h6" variant="h6" fontWeight="bold">
              No Credentials Found
          </SoftTypography>
          <SoftButton component="button" variant="text" size="small" color={"primary"} startIcon={<AddIcon />} onClick={handleModalOpen}>Add New</SoftButton>
      </SoftBox>
    </ListItemCard>
  }

  return (
    <>
      <ContainerSoftBox p={2}>
        <SoftBox display="flex" justifyContent="start" alignItems="center">
          <SoftBox display="flex" justifyContent="start" alignItems="start" sx={{width: "100%"}}>
            <SoftAvatar
                  src={website?.logo? website?.logo?.url: "/assets/images/404.jpg"}
                  alt="profile-image"
                  variant="rounded"
                  size="md"
                  shadow="sm"
              />
              <FlexSoftBox>
                  <StyledSoftBox px={2} display="flex" justifyContent="start" alignItems="start">
                      <SoftTypography component="h6" variant="h6" fontWeight="bold">
                          {website?.name}
                      </SoftTypography>
                      <WebsiteDomain link={website?.url} />
                  </StyledSoftBox>
              </FlexSoftBox>
          </SoftBox>
        </SoftBox>
        <Divider />
        <SoftBox display="flex" justifyContent="start" alignItems="center" mb={2}>
          <SoftTypography component="h6" variant="h6" fontWeight="bold" sx={{flex: 1}}>
            Credentials
          </SoftTypography>
          <IconButton color="secondary" onClick={() => {
            setVisibility(!isVisible)
          }}>
            {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
          <IconButton color="primary" onClick={() => {
            onClickUpdateBtn(null);
          }}>
            <AddIcon />
          </IconButton>
        </SoftBox>

        <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {credentials.map(credential => {
            return <CedentialListItem credential={credential} />;
          })}
          {!credentials.length && <EmptyListCard />}
        </SoftBox>
        
      </ContainerSoftBox>
      {isOpen && <AddCredentialsModal />}
    </>
  );
}

export default CredentialsList;
