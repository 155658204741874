import React, { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import { useForm } from 'react-hook-form';
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import Grid from '@mui/material/Unstable_Grid2';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SoftButton from "components/SoftButton";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { defaulterSingleSearch } from "context/ApiService";

function SingleSearchForm() {

  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();

  const triggerSubmit = (formData, event) => {
      searchDefaulter(formData);
  }

  const searchDefaulter = async (formData) => {
      try{
          let response = await defaulterSingleSearch(formData);
          let { success, data, message } = response.data;
          if(success){
            reset();
          }
      }catch(error){

      }
  }

  return (
    <SoftBox component="form" onSubmit={handleSubmit((data, event) => triggerSubmit(data, event))} role="form">
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" px={3} pb={1}>
        <SoftTypography variant="h6">Personal Information</SoftTypography>
      </SoftBox>
      <Card sx={{marginBottom: "25px"}}>
        <SoftBox p={3}>
          <Grid container spacing={3}>
              <Grid xs={3}>
                <SoftBox>
                  <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Name
                      </SoftTypography>
                  </SoftBox>
                  <SoftInput
                      type="text"
                      {...register('name', {
                          required: true
                      })}
                      placeholder="Name"
                      error={errors.name}
                  />
                  {errors.name && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid name.</SoftTypography>}
                </SoftBox>
              </Grid>
              <Grid xs={3}>
                <SoftBox>
                  <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Date of Birth
                      </SoftTypography>
                  </SoftBox>
                  {/* <input type="hidden" {...register('dateOfBirth')}/> */}
                  <DatePicker
                      type="text"
                      onChange={(date) => {
                        setValue("dateOfBirth", date.toDate())
                      }}
                      placeholder="Date of Birth"
                      error={errors.dateOfBirth}
                  />
                  {errors.dateOfBirth && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid Date of Birth</SoftTypography>}
                </SoftBox>
              </Grid>
              <Grid xs={3}>
                <SoftBox>
                  <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Email Address
                      </SoftTypography>
                  </SoftBox>
                  <SoftInput
                      type="text"
                      {...register('emailAddress')}
                      placeholder="Email Address"
                      error={errors.emailAddress}
                  />
                  {errors.emailAddress && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid Email address</SoftTypography>}
                </SoftBox>
              </Grid>
              <Grid xs={3}>
                <SoftBox>
                  <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Mobile Number
                      </SoftTypography>
                  </SoftBox>
                  <SoftInput
                      type="text"
                      {...register('mobileNumber', {
                          required: true
                      })}
                      placeholder="Mobile Number"
                      error={errors.mobileNumber}
                  />
                  {errors.mobileNumber && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid Mobile Number</SoftTypography>}
                </SoftBox>
              </Grid>
              <Grid xs={3}>
                <SoftBox>
                  <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Organization
                      </SoftTypography>
                  </SoftBox>
                  <SoftInput
                      type="text"
                      {...register('organization')}
                      placeholder="Organization"
                      error={errors.organization}
                  />
                  {errors.organization && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid Organization</SoftTypography>}
                </SoftBox>
              </Grid>
              <Grid xs={6}>
                <SoftBox>
                  <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Address
                      </SoftTypography>
                  </SoftBox>
                  <SoftInput
                      type="text"
                      {...register('address')}
                      placeholder="Address"
                      error={errors.address}
                  />
                  {errors.address && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid Address</SoftTypography>}
                </SoftBox>
              </Grid>
          </Grid>
        </SoftBox>
      </Card>

      <SoftBox display="flex" justifyContent="space-between" alignItems="center" px={3} pb={1}>
        <SoftTypography variant="h6">Educational Information</SoftTypography>
      </SoftBox>
      <Card sx={{marginBottom: "25px"}}>
        <SoftBox p={3}>
          <Grid container spacing={3}>
            <Grid xs={3}>
              <SoftBox>
                <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                    College
                    </SoftTypography>
                </SoftBox>
                <SoftInput
                    type="text"
                    {...register('college')}
                    placeholder="College"
                    error={errors.college}
                />
                {errors.college && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid College</SoftTypography>}
              </SoftBox>
            </Grid>
            <Grid xs={3}>
              <SoftBox>
                <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Start of College
                    </SoftTypography>
                </SoftBox>
                <DatePicker
                    type="text"
                    onChange={(date) => {
                      setValue("collegeStartDate", date.toDate())
                    }}
                    placeholder="College Start Date"
                    error={errors.dateOfBirth}
                />
                {errors.collegeStartDate && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid College start date</SoftTypography>}
              </SoftBox>
            </Grid>
            <Grid xs={3}>
              <SoftBox>
                <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                    End of College
                    </SoftTypography>
                </SoftBox>
                <DatePicker
                    type="text"
                    onChange={(date) => {
                      setValue("collegeEndDate", date.toDate())
                    }}
                    placeholder="College End Date"
                    error={errors.dateOfBirth}
                />
                {errors.collegeEndDate && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid End of College</SoftTypography>}
              </SoftBox>
            </Grid>
            <Grid xs={3}>
              <SoftBox>
                <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Degree
                    </SoftTypography>
                </SoftBox>
                <SoftInput
                    type="text"
                    {...register('degreeName')}
                    placeholder="Degree"
                    error={errors.degreeName}
                />
                {errors.degreeName && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid Degree</SoftTypography>}
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </Card>

      <SoftBox display="flex" justifyContent="space-between" alignItems="center" px={3} pb={1}>
        <SoftTypography variant="h6">Government Information</SoftTypography>
      </SoftBox>
      <Card sx={{marginBottom: "25px"}}>
        <SoftBox p={3}>
          <Grid container spacing={3}>
            <Grid xs={3}>
              <SoftBox>
                <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Aadhaar Number
                    </SoftTypography>
                </SoftBox>
                <SoftInput
                    type="text"
                    {...register('aadhaarNumber')}
                    placeholder="Aadhaar Number"
                    error={errors.aadhaarNumber}
                />
                {errors.aadhaarNumber && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid Aadhaar Number</SoftTypography>}
              </SoftBox>
            </Grid>
            <Grid xs={3}>
              <SoftBox>
                <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      PAN Card
                    </SoftTypography>
                </SoftBox>
                <SoftInput
                    type="text"
                    {...register('panNumber')}
                    placeholder="PAN"
                    error={errors.panNumber}
                />
                {errors.panNumber && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid PAN</SoftTypography>}
              </SoftBox>
            </Grid>
            <Grid xs={3}>
              <SoftBox>
                <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Driving License
                    </SoftTypography>
                </SoftBox>
                <SoftInput
                    type="text"
                    {...register('drivingLicense')}
                    placeholder="Driving license"
                    error={errors.drivingLicense}
                />
                {errors.drivingLicense && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid Driving License</SoftTypography>}
              </SoftBox>
            </Grid>
            <Grid xs={3}>
              <SoftBox>
                <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Registration Certificate (RC)
                    </SoftTypography>
                </SoftBox>
                <SoftInput
                    type="text"
                    {...register('rcNumber')}
                    placeholder="RC"
                    error={errors.rcNumber}
                />
                {errors.rcNumber && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid RC</SoftTypography>}
              </SoftBox>
            </Grid>
            <Grid xs={3}>
              <SoftBox>
                <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Vehicle Registration Number
                    </SoftTypography>
                </SoftBox>
                <SoftInput
                    type="text"
                    {...register('vehicleNumber')}
                    placeholder="Vehicle Number"
                    error={errors.vehicleNumber}
                />
                {errors.vehicleNumber && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid Vehicle Number</SoftTypography>}
              </SoftBox>
            </Grid>
            <Grid xs={3}>
              <SoftBox>
                <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                    GST Number
                    </SoftTypography>
                </SoftBox>
                <SoftInput
                    type="text"
                    {...register('gstNumber')}
                    placeholder="Name"
                    error={errors.gstNumber}
                />
                {errors.gstNumber && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid GST Number</SoftTypography>}
              </SoftBox>
            </Grid>
            <Grid xs={3}>
              <SoftBox>
                <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Voter ID
                    </SoftTypography>
                </SoftBox>
                <SoftInput
                    type="text"
                    {...register('voterId')}
                    placeholder="Voter ID"
                    error={errors.voterId}
                />
                {errors.voterId && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid Voter ID</SoftTypography>}
              </SoftBox>
            </Grid>
            <Grid xs={3}>
              <SoftBox>
                <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Universal Account Number
                    </SoftTypography>
                </SoftBox>
                <SoftInput
                    type="text"
                    {...register('universalAccountNumber')}
                    placeholder="UAN"
                    error={errors.universalAccountNumber}
                />
                {errors.universalAccountNumber && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid UAN</SoftTypography>}
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </Card>

      <SoftBox display="flex" justifyContent="space-between" alignItems="center" px={3} pb={1}>
        <SoftTypography variant="h6">Relative Information</SoftTypography>
      </SoftBox>
      <Card sx={{marginBottom: "25px"}}>
        <SoftBox p={3}>
          <Grid container spacing={3}>
              <Grid xs={3}>
                  
              </Grid>
          </Grid>
        </SoftBox>
      </Card>

      <SoftBox display="flex" justifyContent="space-between" alignItems="center" px={3} pb={1}>
        <SoftTypography variant="h6">Additional Details</SoftTypography>
      </SoftBox>
      <Card sx={{marginBottom: "25px"}}>
        <SoftBox p={3}>
          <Grid container spacing={3}>
            <Grid xs={6}>
              <SoftBox>
                <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Other Details
                    </SoftTypography>
                </SoftBox>
                <SoftInput
                    type="text"
                    {...register('otherDetails')}
                    placeholder="Other Details"
                    error={errors.otherDetails}
                />
                {errors.otherDetails && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid Other Details</SoftTypography>}
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </Card>

      <SoftBox display="flex" justifyContent="start" alignItems="center" px={3} pb={1}>
          <SoftButton type="submit" variant="gradient" color="dark" startIcon={<SearchOutlinedIcon />}>
              Search
          </SoftButton>
          <SoftButton type="button" variant="text" color="error" sx={{ marginLeft: "15px"}}>
              Cancel
          </SoftButton>
      </SoftBox>
    </SoftBox>
  );
}

export default SingleSearchForm;
