import React, { useEffect, useState } from "react";

import SoftButton from "components/SoftButton";
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useForm } from 'react-hook-form';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftAlert from "components/SoftAlert";
import AddNewUserForm from "./AddNewUserForm";

const AddNewUser = (props) => {
    const [isOpne, setOpen] = useState(false);

    const handleModalOpen = () => {
        setOpen(true);
    }

    const handleModalClose = (event, reason) => {
        if (reason && reason === "backdropClick")   return; 
        setOpen(false);
    }
    
    const AddClientModal = () => {
        return (
            <Dialog onClose={handleModalClose} open={true}>
                <DialogTitle>Add New User</DialogTitle>
                <DialogContent>
                    <AddNewUserForm {...props} setOpen={setOpen} isOpne={isOpne} />
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <>
            <SoftButton type="button" color="primary" onClick={handleModalOpen}>
                Add&nbsp;
                <AddTwoToneIcon />
            </SoftButton>
            {isOpne && <AddClientModal />}
        </>
    )
}
export default AddNewUser;