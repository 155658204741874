import React, { useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { loadClients } from "context/ApiService";
import { styled } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import UpdateClient from "./update-client";

function BrandsList(props) {

    const { reloadState } = props;
    const [reload, setReload] = reloadState;

    const ClientLogo = styled('img')({
        width: "25px",
        height: "auto",
        marginLeft: "5px"
    });
    const ClientNameTypography = styled(SoftTypography)({
        marginLeft: "30px"
    });

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });

    const [rowCountState, setRowCountState] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);

    const RenderClientLogo = (props) => {
        const { value, row } = props;
        let { logo } = row
        return (
            <SoftBox display="flex" alignItems="center">
                <ClientLogo src={logo?.url} />
                <ClientNameTypography variant="button" fontWeight="medium">
                {value}
                </ClientNameTypography>
            </SoftBox>
        );
    }
    const RenderClientCreated = (props) => {
        const { value } = props;
        let date = new Date(value).toLocaleString("en-IN", { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true})
        return (
            <SoftBox display="flex" alignItems="center">
                <SoftTypography variant="button" color="text" fontWeight="medium">
                    {date}
                </SoftTypography>
            </SoftBox>
        );
    }

    const RenderClientCode = (props) => {
        const { value } = props;
        return (
            <SoftBox display="flex" alignItems="center">
                <SoftTypography variant="button" color="text" fontWeight="medium">
                    {value}
                </SoftTypography>
            </SoftBox>
        );
    }
    
    const RenderClientActions = (props) => {
        const { value, row } = props;
        return (
            <SoftBox display="flex" alignItems="center">
                <UpdateClient reloadState={reloadState} client={row} />
            </SoftBox>
        );
    }

    const columns = [
        { field: "name", headerName: "Client", align: "left", flex: 1, sortable: false, hideable: false, renderCell: RenderClientLogo},
        { field: "code", headerName: "Code", align: "left", sortable: false, hideable: false, renderCell: RenderClientCode },
        { field: "createdAt", headerName: "Created On", align: "left", flex: 1, sortable: false, hideable: false, renderCell: RenderClientCreated},
        { field: "uid", headerName: "Action", align: "left", sortable: false, hideable: false, renderCell: RenderClientActions},
    ];

    useEffect(() => {
        LoadData();
    }, []);

    useEffect(() => {
        LoadData();
    }, [paginationModel]);

    useEffect(() => {
        if(reload) {
            LoadData();
            setReload(false);
        }
    }, [reload]);

    const LoadData = async () => {
        setLoading(true);
        try{
            let searchData = {
                "page": paginationModel.page,
                "limit": paginationModel.pageSize
            };

            let response = await loadClients(searchData);

            setLoading(false);

            let { success, data, message } = response.data;

            if(success){
                
                let { data: clients, limit, page, total } = data;
                setRowCountState(total);
                setRows(clients);
            }
        }catch(error){
            setLoading(false);
        }
    }

    return (
        <>
        <DataGrid
            columns={columns}
            rows={rows}
            rowCount={rowCountState}
            loading={isLoading}
            pageSizeOptions={[10, 50, 100]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            getRowId={(row) => row.uid}
            disableColumnMenu
            disableRowSelectionOnClick
            sx={{
                boxShadow: 0,
                border: 0,
                "& .MuiInputBase-root": {
                    width: "70px !important"
                },
                "& .MuiTablePagination-select": {
                    textAlignLast: "center !important"
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                 },
                 "& .MuiDataGrid-virtualScroller": {
                    minHeight: "50px !important"
                 }
            }}
        />
        </>
    );
}

export default BrandsList;
