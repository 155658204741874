/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftAlert from "components/SoftAlert";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
import { useForm } from 'react-hook-form';
import { login } from "context/ApiService";
import { useUser } from "context/providers/UserProvider";
import TermsAndConditions from "../terms-and-conditions";

function SignIn() {

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger
  } = useForm();

  const [isSubmitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState({show: false, message: "", state: "success"});
  const [agreement, setAgremment] = useState(false);
  const [openTnc, setOpenTnc] = useState(false);
  
  const navigate = useNavigate();
  const { loadMe } = useUser();
  const SwalAlert = withReactContent(Swal)

  const handleSetAgremment = () => setAgremment(!agreement);

  const handleRequest = () => {
    SwalAlert.fire({
      title: "Thank You!",
      text: "Unfortunately, Dapro is curently request only application and you'll be able to acccess soon!"
    });
  }

  const handleToggle = () => {
    setOpenTnc((tnc) => !tnc);
  }

  const triggerSubmit = async (data) => {
      try{
        let response = await login(data);
        let { success, data: respData, message } = response?.data;
        setMessage({show: true, message: message, state: success ? "success" : "error"});
        let { token } = respData;
        window.localStorage.setItem("token", token);
        loadMe();
        navigate("/dashboard");
      }catch(error) {
        if(error?.response?.status == 401){
          setMessage({show: true, message: error?.response?.data?.message, state: "error"})
        }
        console.log(error, "EEEEE");
      }
  }

  return (
   <>
     <CoverLayout
      title="Welcome back"
      description="Enter your email and password to sign in"
      image={curved9}
    >
      {message?.show && <SoftAlert color={message?.state}>
      <SoftTypography color="loght" component="label" variant="caption" fontWeight="bold">{message?.message}</SoftTypography></SoftAlert>}
      <SoftBox component="form" onSubmit={handleSubmit((data) => triggerSubmit(data))} role="form">
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="email"
            {...register('emailAddress', {
                required: 'Email is required',
                pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email',
                },
            })}
            placeholder="Email"
            error={errors.emailAddress}
          />
          {errors.emailAddress && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid email address</SoftTypography>}
        </SoftBox>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="password"
            {...register('password', { required: true })}
            placeholder="Password"
            error={errors.password}
          />
          {errors.password && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid email address</SoftTypography>}
        </SoftBox>
  
        <Checkbox  checked={agreement} onChange={handleSetAgremment} />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                &nbsp;I agree the&nbsp;
                <SoftTypography variant="button" fontWeight="bold" sx={{ cursor: "poiner" }} color="error" textGradient={true} onClick={() => { handleToggle() }}>Terms and Conditions</SoftTypography>
              </SoftTypography>
        <SoftBox mt={4} mb={1}>
          <SoftButton type="submit" variant="gradient" color="error" fullWidth disabled={!agreement}>
            {isSubmitting ? 'VALIDATING' : 'SIGN IN'}
          </SoftButton>
        </SoftBox>
        {/* <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <SoftTypography
              component={Link}
              // to="/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
              onClick={handleRequest}
            >
              Request Access
            </SoftTypography>
          </SoftTypography>
        </SoftBox> */}
      </SoftBox>
    </CoverLayout>
    {openTnc && <TermsAndConditions handleClose={handleToggle} />}
   </>
  );
}

export default SignIn;
