import React, { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import ListOfDatasources from "./ListOfDatasources";

import { useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SoftButton from "components/SoftButton";
import MapIcon from '@mui/icons-material/SwapHoriz';

function Datasources(props) {
    const [levelVal, setLevelVal] = useState('');
    const [levels, setLevels] = useState([]);

    return (
        <DashboardLayout>
        <DashboardNavbar />
        <SoftBox py={3}>
            <SoftBox display="flex" mb={5} justifyContent="end" alignItems="end" sx={{width: "100%"}}>
                <SoftBox mb={1} mr={3} display="flex" sx={{ width: "250px" }}>
                    <Autocomplete
                        disablePortal
                        disableClearable
                        id="combo-box-demo"
                        value={levelVal}
                        inputValue={levelVal}
                        options={levels}
                        onChange={(event, newValue) => {
                            let { value } = newValue;
                            setLevelVal(value);
                        }}
                        renderInput={(params) => <TextField {...params}/>}
                    />
                    <SoftBox ml={2} display="flex" alignItems="center">
                        <MapIcon sx={{ cursor: "pointer" }}/>
                    </SoftBox>
                    {/* {errors.level && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid Level</SoftTypography>} */}
                </SoftBox>
            </SoftBox>
            <ListOfDatasources {...props} />
        </SoftBox>
        <Footer />
        </DashboardLayout>
    );
}

export default Datasources;
